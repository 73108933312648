var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modalContent",staticClass:"modal fade modal-irispro",attrs:{"tabindex":"-1","role":"dialog","data-backdrop":"static","aria-hidden":"true","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-dialog",staticStyle:{"max-width":"1820px"},attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"}),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.hide}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-form"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"fm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"modal-title"},[_vm._v("Register New Customer")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticStyle:{"width":"350px"}},[_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"name","name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.allowSearchCustomer)?_c('searchable-dropdown',{class:{
                          'is-invalid': errors.length > 0,
                        },attrs:{"placeholder":"Full Name as Per I.C./Passport","minWidth":350,"options":_vm.customerSearchByNameDropdown,"isLoading":_vm.customerSearchByNameIsSearching,"showCreate":true,"createText":"Register New Customer"},on:{"createNew":_vm.createNewCustomerByName,"search":function (searchTerm) {
                            _vm.debounceSearchCustomerByName(searchTerm);
                          },"selected":function (val) {_vm.selectExistingCustomer(val, _vm.customerSearchByNameList)}},model:{value:(_vm.value.customerId),callback:function ($$v) {_vm.$set(_vm.value, "customerId", $$v)},expression:"value.customerId"}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.name),expression:"value.name"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Full Name as Per I.C./Passport"},domProps:{"value":(_vm.value.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"phone","name":"phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.phone),expression:"value.phone"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Contact No."},domProps:{"value":(_vm.value.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"email","name":"email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.email),expression:"value.email"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Email Address"},domProps:{"value":(_vm.value.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticStyle:{"width":"350px","margin-left":"25px"}},[_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"ic","name":"i.c./passport no."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [(_vm.allowSearchCustomer)?_c('searchable-dropdown',{class:{
                          'is-invalid': errors.length > 0,
                        },attrs:{"placeholder":"I.C./Passport No.","minWidth":350,"options":_vm.customerSearchByIcDropdown,"isLoading":_vm.customerSearchByIcIsSearching,"showCreate":true,"createText":"Register New Customer"},on:{"createNew":_vm.createNewCustomerByIc,"search":function (searchTerm) {
                            _vm.debounceSearchCustomerByIc(searchTerm);
                          },"selected":function (val) {_vm.selectExistingCustomer(val, _vm.customerSearchByIcList)}},model:{value:(_vm.value.customerId),callback:function ($$v) {_vm.$set(_vm.value, "customerId", $$v)},expression:"value.customerId"}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.ic),expression:"value.ic"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"I.C./Passport No."},domProps:{"value":(_vm.value.ic)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "ic", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"customeraddress.address1","name":"address line 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customerAddress.address1),expression:"value.customerAddress.address1"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Address Line 1"},domProps:{"value":(_vm.value.customerAddress.address1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.customerAddress, "address1", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"vid":"customeraddress.address2","name":"address line 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customerAddress.address2),expression:"value.customerAddress.address2"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Address Line 2"},domProps:{"value":(_vm.value.customerAddress.address2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.customerAddress, "address2", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticStyle:{"width":"350px","margin-left":"25px"}},[_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"customeraddress.country","name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customerAddress.country),expression:"value.customerAddress.country"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.value.customerAddress, "country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.countries),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"customeraddress.state","name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customerAddress.state),expression:"value.customerAddress.state"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.value.customerAddress, "state", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.stateDropdowns),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"customeraddress.city","name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customerAddress.city),expression:"value.customerAddress.city"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"City"},domProps:{"value":(_vm.value.customerAddress.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.customerAddress, "city", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"vid":"customeraddress.postcode","name":"postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.customerAddress.postcode),expression:"value.customerAddress.postcode"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Postcode"},domProps:{"value":(_vm.value.customerAddress.postcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.customerAddress, "postcode", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('hr',{staticClass:"form-hr-red"}),_c('div',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.value.warrantyTypeChoice === 1 ? 'Tinted Address' : 'Tinted Vehicle Details'))]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticStyle:{"width":"350px"}},[_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"name":"warranty type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","disabled":""},domProps:{"value":_vm.getWarrantyTypeDisplay(_vm.value.warrantyTypeChoice)}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"invoice","name":"invoice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.invoice),expression:"value.invoice"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Invoice"},domProps:{"value":(_vm.value.invoice)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "invoice", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"vid":"installationDate","name":"installation date","rules":"required|date_format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.installationDateUi),expression:"value.installationDateUi"}],ref:"inputInstallationDate",class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Installation Date","autocomplete":"off"},domProps:{"value":(_vm.value.installationDateUi)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "installationDateUi", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 1 && !_vm.isCopyCustomerAddress),expression:"value.warrantyTypeChoice === 1 && !isCopyCustomerAddress"}],staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-iris",attrs:{"type":"button"},on:{"click":_vm.copyCustomerAddressToBuildingAddress}},[_vm._v(" Copy Customer Address ")])])]),_c('div',{staticStyle:{"width":"350px","margin-left":"25px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 1),expression:"value.warrantyTypeChoice === 1"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"buildingaddress.country","name":"country","rules":_vm.value.warrantyTypeChoice === 1 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.buildingAddress.country),expression:"value.buildingAddress.country"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.value.buildingAddress, "country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.countries),function(option2,index2){return _c('option',{key:index2,domProps:{"value":option2.value}},[_vm._v(" "+_vm._s(option2.text)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 1),expression:"value.warrantyTypeChoice === 1"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"buildingaddress.state","name":"state","rules":_vm.value.warrantyTypeChoice === 1 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.buildingAddress.state),expression:"value.buildingAddress.state"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.value.buildingAddress, "state", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.stateDropdownsBuilding),function(option,index2){return _c('option',{key:index2,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 1),expression:"value.warrantyTypeChoice === 1"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"buildingaddress.city","name":"city","rules":_vm.value.warrantyTypeChoice === 1 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.buildingAddress.city),expression:"value.buildingAddress.city"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"City"},domProps:{"value":(_vm.value.buildingAddress.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.buildingAddress, "city", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 2),expression:"value.warrantyTypeChoice === 2"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"make","name":"make","rules":_vm.value.warrantyTypeChoice === 2 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.make),expression:"value.make"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Make"},domProps:{"value":(_vm.value.make)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "make", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 2),expression:"value.warrantyTypeChoice === 2"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"carRegistrationNo","name":"car registration no.","rules":_vm.value.warrantyTypeChoice === 2 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.carRegistrationNo),expression:"value.carRegistrationNo"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Car Registration No."},domProps:{"value":(_vm.value.carRegistrationNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "carRegistrationNo", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 2),expression:"value.warrantyTypeChoice === 2"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"model","name":"model","rules":_vm.value.warrantyTypeChoice === 2 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.model),expression:"value.model"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Model"},domProps:{"value":(_vm.value.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "model", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticStyle:{"width":"350px","margin-left":"25px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 1),expression:"value.warrantyTypeChoice === 1"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"buildingaddress.address1","name":"address line 1","rules":_vm.value.warrantyTypeChoice === 1 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.buildingAddress.address1),expression:"value.buildingAddress.address1"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Address Line 1"},domProps:{"value":(_vm.value.buildingAddress.address1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.buildingAddress, "address1", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 1),expression:"value.warrantyTypeChoice === 1"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"buildingaddress.address2","name":"address line 2","rules":_vm.value.warrantyTypeChoice === 1 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.buildingAddress.address2),expression:"value.buildingAddress.address2"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Address Line 2"},domProps:{"value":(_vm.value.buildingAddress.address2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.buildingAddress, "address2", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 1),expression:"value.warrantyTypeChoice === 1"}]},[_c('ValidationProvider',{attrs:{"vid":"buildingaddress.postcode","name":"postcode","rules":_vm.value.warrantyTypeChoice === 1 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.buildingAddress.postcode),expression:"value.buildingAddress.postcode"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Postcode"},domProps:{"value":(_vm.value.buildingAddress.postcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.buildingAddress, "postcode", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 2),expression:"value.warrantyTypeChoice === 2"}],staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"chasisno","name":"chasis no.","rules":_vm.value.warrantyTypeChoice === 2 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.chasisNo),expression:"value.chasisNo"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Chasis No."},domProps:{"value":(_vm.value.chasisNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "chasisNo", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 2),expression:"value.warrantyTypeChoice === 2"}]},[_c('ValidationProvider',{attrs:{"vid":"colour","name":"colour","rules":_vm.value.warrantyTypeChoice === 2 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.colour),expression:"value.colour"}],class:{
                            'form-control': true,
                            'is-invalid': errors.length > 0,
                          },attrs:{"type":"text","placeholder":"Colour"},domProps:{"value":(_vm.value.colour)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "colour", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('hr',{staticClass:"form-hr-red"}),_vm._l((_vm.value.warrantyDetails),function(warrantyDetail,index){return _c('div',{key:index,staticClass:"d-flex justify-content-center",class:{
                  'mb-3': index !== _vm.value.warrantyDetails.length - 1,
                }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 1),expression:"value.warrantyTypeChoice === 1"}],staticStyle:{"width":"270px"}},[_c('ValidationProvider',{attrs:{"vid":("tintedLocation" + index),"name":"tinted location","rules":_vm.value.warrantyTypeChoice === 1 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                          _vm.value.warrantyDetails[index].tintedLocation
                        ),expression:"\n                          value.warrantyDetails[index].tintedLocation\n                        "}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },attrs:{"type":"text","placeholder":"Tinted Location"},domProps:{"value":(
                          _vm.value.warrantyDetails[index].tintedLocation
                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.warrantyDetails[index], "tintedLocation", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.warrantyTypeChoice === 2),expression:"value.warrantyTypeChoice === 2"}],staticStyle:{"width":"270px"}},[_c('ValidationProvider',{attrs:{"vid":("tintedLocation" + index),"name":"tinted location","rules":_vm.value.warrantyTypeChoice === 2 ? 'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.warrantyDetails[index].tintedLocation),expression:"value.warrantyDetails[index].tintedLocation"}],class:{
                          'form-control': true,
                          'is-invalid': errors.length > 0,
                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.value.warrantyDetails[index], "tintedLocation", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.autoTintedLocations),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"width":"270px","margin-left":"25px"}},[_c('ValidationProvider',{attrs:{"vid":("model" + index),"name":"model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('searchable-dropdown',{class:{
                        'is-invalid': errors.length > 0,
                      },attrs:{"placeholder":"Model","minWidth":270,"options":_vm.productDropdownList[index],"displayValue":_vm.value.warrantyDetails[index].model},on:{"search":function (searchTerm) {
                          _vm.searchProductList(index, searchTerm);
                        },"selected":function (value) {_vm.selectWarrantyDetailModel(index,value);}},model:{value:(_vm.value.warrantyDetails[index].productId),callback:function ($$v) {_vm.$set(_vm.value.warrantyDetails[index], "productId", $$v)},expression:"value.warrantyDetails[index].productId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"width":"270px","margin-left":"25px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.warrantyDetails[index].serialNo),expression:"value.warrantyDetails[index].serialNo"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Serial No.","readonly":""},domProps:{"value":(_vm.value.warrantyDetails[index].serialNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.warrantyDetails[index], "serialNo", $event.target.value)}}})]),_c('div',{staticStyle:{"width":"270px","margin-left":"25px"}},[_c('ValidationProvider',{attrs:{"vid":("warrantySealId" + index),"name":"warranty seal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('searchable-dropdown',{class:{
                        'is-invalid': errors.length > 0,
                      },attrs:{"placeholder":"Warranty Seal","minWidth":270,"options":_vm.warrantySealDropdownList[index],"displayValue":_vm.value.warrantyDetails[index].warrantySealId ? ("" + (_vm.value.warrantyDetails[index].warrantySealRunNo)) : ''},on:{"search":function (searchTerm) {
                          _vm.searchWarrantySealList(index, searchTerm);
                        },"selected":function (value) {_vm.selectWarrantySeal(index,value);}},model:{value:(_vm.value.warrantyDetails[index].warrantySealId),callback:function ($$v) {_vm.$set(_vm.value.warrantyDetails[index], "warrantySealId", $$v)},expression:"value.warrantyDetails[index].warrantySealId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"width":"200px","margin-left":"25px"}},[_c('ValidationProvider',{attrs:{"vid":("totalSqFt" + index),"name":"total sq. ft.","rules":_vm.totalSqftValidation(index),"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('auto-decimal-input',{class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Total Sq. Ft."},model:{value:(
                        _vm.value.warrantyDetails[index].totalSqFt
                      ),callback:function ($$v) {_vm.$set(_vm.value.warrantyDetails[index], "totalSqFt", $$v)},expression:"\n                        value.warrantyDetails[index].totalSqFt\n                      "}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"width":"250px","margin-left":"25px"}},[_c('ValidationProvider',{attrs:{"vid":("startDate" + index),"name":"start date","rules":"required|date_format"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.warrantyDetails[index].startDateUi),expression:"value.warrantyDetails[index].startDateUi"}],ref:("inputStartDate" + index),refInFor:true,class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Start Date"},domProps:{"value":(_vm.value.warrantyDetails[index].startDateUi)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.warrantyDetails[index], "startDateUi", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"margin-left":"25px","padding-top":"11px"}},[_c('button',{staticClass:"btn btn-iris-modal btn-iris-delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteWarrantyDetails(index)}}})])])}),_c('div',{staticClass:"content-add-layer"},[_c('a',{staticClass:"btn-add-layer",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.addWarrantyDetails}},[_vm._v("+ Add Layer")])]),_c('button',{ref:"hiddenSubmit",staticClass:"invisible",attrs:{"type":"submit"}})],2)]}}])})],1),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-white",domProps:{"innerHTML":_vm._s(_vm.errorMessage)}})])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-button d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-iris",attrs:{"disabled":_vm.value.isSubmitting},on:{"click":function($event){return _vm.$refs.hiddenSubmit.click()}}},[_vm._v(" Register Customer ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }