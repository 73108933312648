


































































































































































































































































































































































































































































































































































































































































































































































































































































/* global $ */
import { VeeValidateObserverRef } from '@/types/libraries/VeeValidateObserverRef';
import { ApiValidationErrorResponse } from '@/types/api/Response';
import Vue from 'vue';
import mixinsVue from './mixins.vue';
import AutoDecimalInput from '@/components/helpers/AutoDecimalInput.vue';
import {
  DropdownOption,
  malaysiaStates,
  autoTintedLocations,
  countries,
  getCountryStates
} from '@/dropdown-options';

import {
  NonAssignedWarrantySeal,
  NonAssignedWarrantySealListResponse
} from '@/types/api/WarrantySeal';
import { RegisterWarrantyWithCustomer } from '@/types/api/RegisterWarrantyWithCustomer';
import {
  ProductRemainderSqFt,
  ProductRemainerSqftListResponse
} from '@/types/api/Product';
import debounce from 'debounce';
import { CustomerDetailResponse, SearchCustomerByNameResponse } from '@/types/api/CustomerList';
export default Vue.extend({
  'name': 'ModalAddProduct',
  'mixins': [mixinsVue],
  'components': {
    AutoDecimalInput
  },
  'props': {
    'value': {
      'type': Object,
      'required': true
    },
    'errorMessage': {
      'type': String,
      'default': ''
    }
  },
  'computed': {
    stateDropdowns ():DropdownOption[] {
      const selectedCountry = this.value.customerAddress.country;

      const states = getCountryStates(selectedCountry);

      states.unshift({
        'text': states.length > 0 ? 'Please select state' : 'Please select country first',
        'value': ''
      });

      return states;
    },
    stateDropdownsBuilding ():DropdownOption[] {
      const selectedCountry = this.value.buildingAddress.country;

      const states = getCountryStates(selectedCountry);

      states.unshift({
        'text': states.length > 0 ? 'Please select state' : 'Please select country first',
        'value': ''
      });

      return states;
    },
    customerSearchByNameDropdown ():Array<DropdownOption> {
      return this.customerSearchByNameList.map((c) => ({
        'text': `${c.name} (IC No.: ${c.ic})`,
        'value': c.id
      }));
    },
    customerSearchByIcDropdown ():Array<DropdownOption> {
      return this.customerSearchByIcList.map((c) => ({
        'text': `${c.ic} (Name: ${c.name})`,
        'value': c.id
      }));
    }
  },
  data () {
    return {
      countries,
      'malaysiaStates': [] as Array<DropdownOption>,
      'autoTintedLocations': [] as Array<DropdownOption>,
      'warrantyTypes': [
        {
          'text': 'Warranty Type: Auto',
          'value': 2
        },
        {
          'text': 'Warranty Type: Building',
          'value': 1
        }
      ] as Array<DropdownOption>,
      'productModels': [] as Array<ProductRemainderSqFt>,
      'warrantySeals': [] as Array<NonAssignedWarrantySeal>,
      'productDropdownList': [] as Array<Array<DropdownOption>>,
      'warrantySealDropdownList': [] as Array<Array<DropdownOption>>,
      'debounceSearchCustomerByName': null as unknown,
      'customerSearchByNameList': [] as CustomerDetailResponse[],
      'customerSearchByNameIsSearching': false,
      'debounceSearchCustomerByIc': null as unknown,
      'customerSearchByIcList': [] as CustomerDetailResponse[],
      'customerSearchByIcIsSearching': false,
      'allowSearchCustomer': true,
      'isCopyCustomerAddress': false
    };
  },
  'watch': {
    'value.warrantyDetails' () {
      this.updateProductDropdownList();
      this.updateWarrantySealDropdownList();

      this.$nextTick(() => {
        this.value.warrantyDetails.forEach((d:any, index:number) => {
          // @ts-expect-error: Ignore error explicitly.
          $(this.$refs[`inputStartDate${index}`]).daterangepicker({
            'singleDatePicker': true,
            'autoApply': true,
            'locale': {
              'format': 'DD/MM/YYYY'
            }
          });

          $(this.$refs[`inputStartDate${index}`]).on(
            'apply.daterangepicker',
            (ev, picker) => {
              this.value.warrantyDetails[index].startDateUi = picker.startDate.format('DD/MM/YYYY');
            }
          );
        });
      });
    },
    productModels () {
      this.updateProductDropdownList();
    },
    warrantySeals () {
      this.updateWarrantySealDropdownList();
    }
  },
  mounted () {
    this.malaysiaStates = malaysiaStates;
    this.autoTintedLocations = autoTintedLocations;

    // @ts-expect-error: Ignore error explicitly.
    $(this.$refs.inputInstallationDate).daterangepicker({
      'singleDatePicker': true,
      'autoApply': true,
      'locale': {
        'format': 'DD/MM/YYYY'
      }
    });

    $(this.$refs.inputInstallationDate).on(
      'apply.daterangepicker',
      (ev, picker) => {
        this.value.installationDateUi = picker.startDate.format('DD/MM/YYYY');
      }
    );

    this.debounceSearchCustomerByName = debounce(this.searchCustomerByName, 500);
    this.debounceSearchCustomerByIc = debounce(this.searchCustomerByIc, 500);
  },
  'methods': {
    onSubmit () {
      this.$emit('submit');
    },
    create () {
      (this.$refs.form as any).reset();
      this.getNonAssignedWarrantySeals();
      this.getProductRemainderSqfts();
      (this as any).show();
    },
    setErrors (errors: ApiValidationErrorResponse) {
      (this.$refs.form as VeeValidateObserverRef).setErrors(errors);
    },
    addWarrantyDetails () {
      this.$emit('addWarrantyDetails');
    },
    deleteWarrantyDetails (index: number) {
      this.$emit('deleteWarrantyDetails', index);
    },
    getNonAssignedWarrantySeals () {
      this.axios.get('non-assigned/all').then(({ data }) => {
        const response = data as NonAssignedWarrantySealListResponse;
        this.warrantySeals = response.result.warrantySeals;
      });
    },
    getProductRemainderSqfts () {
      this.axios.get('product/remainder-sq-ft').then(({ data }) => {
        const response = data as ProductRemainerSqftListResponse;
        this.productModels = response.result.products;
      });
    },
    generateProductModelsDropdown (searchTerm: string | null): Array<DropdownOption> {
      let dropdowns = this.productModels.map((p) => ({
        'text': `${p.model} - Serial No: ${p.serialNo}, Remaining Sqft: ${p.unregSqFt.toFixed(1)}`,
        'value': p.id
      }));

      if (searchTerm) {
        dropdowns = dropdowns.filter((d) => d.text.indexOf(searchTerm) > -1);
      }
      return dropdowns;
    },
    searchProductList (rowIndex: number, searchTerm: string | null) {
      this.$set(
        this.productDropdownList,
        rowIndex,
        this.generateProductModelsDropdown(searchTerm)
      );
    },
    selectWarrantyDetailModel (index:number, value:number) {
      const selectedWarrantyDetails = (this.value as RegisterWarrantyWithCustomer).warrantyDetails[index];

      const product = this.productModels.find((p) => p.id === value);
      if (product) {
        selectedWarrantyDetails.productId = product.id;
        selectedWarrantyDetails.model = product.model;
        selectedWarrantyDetails.serialNo = product.serialNo;
      }
    },
    totalSqftValidation (index: number) {
      const selectedWarrantyDetails = (this.value as RegisterWarrantyWithCustomer).warrantyDetails[index];

      if (selectedWarrantyDetails.productId) {
        const product = this.productModels.find((p) => p.id === selectedWarrantyDetails.productId);

        if (product) {
          return `required|double:1|min_value:0.1|max_value:${product.unregSqFt.toFixed(1)}`;
        }
      }
      return 'required|double:1|min_value:0.1';
    },
    generateWarrantySealDropdown (searchTerm: string | null, exclude: Array<number|null>): Array<DropdownOption> {
      let dropdowns = this.warrantySeals.map((ws) => ({
        'text': `WARRANTY SEAL NO. ${ws.runNo}`,
        'value': ws.id
      }));

      if (searchTerm) {
        dropdowns = dropdowns.filter((d) => d.text.indexOf(searchTerm) > -1);
      }

      if (exclude) {
        dropdowns = dropdowns.filter((d) => !exclude.includes(d.value));
      }
      return dropdowns;
    },
    searchWarrantySealList (rowIndex: number, searchTerm: string | null) {
      const notThisRowWarrantyDetails = (this.value as RegisterWarrantyWithCustomer).warrantyDetails.filter((w, index) => index !== rowIndex);
      const selectedWarrantySeals = notThisRowWarrantyDetails.map(d => d.warrantySealId);
      const warrantySealsWithValues = selectedWarrantySeals.filter(ws => !!ws);

      this.$set(
        this.warrantySealDropdownList,
        rowIndex,
        this.generateWarrantySealDropdown(searchTerm, warrantySealsWithValues)
      );
    },
    updateProductDropdownList () {
      this.$nextTick(() => {
        this.productDropdownList = this.value.warrantyDetails.map(() => {
          return this.generateProductModelsDropdown(null);
        });
      });
    },
    updateWarrantySealDropdownList () {
      this.$nextTick(() => {
        this.warrantySealDropdownList = this.value.warrantyDetails.map(() => {
          return this.generateWarrantySealDropdown(null, []);
        });
      });
    },
    selectWarrantySeal (index:number, value:number) {
      const selectedWarrantyDetails = (this.value as RegisterWarrantyWithCustomer).warrantyDetails[index];

      const warrantySeal = this.warrantySeals.find((ws) => ws.id === value);
      if (warrantySeal) {
        selectedWarrantyDetails.warrantySealId = warrantySeal.id;
        selectedWarrantyDetails.warrantySealRunNo = warrantySeal.runNo;
      }
    },
    copyCustomerAddressToBuildingAddress () {
      this.isCopyCustomerAddress = true;
      const form = this.value as RegisterWarrantyWithCustomer;

      if (form.warrantyTypeChoice === 1 && form.buildingAddress) {
        form.buildingAddress.address1 = form.customerAddress.address1;
        form.buildingAddress.address2 = form.customerAddress.address2;
        form.buildingAddress.postcode = form.customerAddress.postcode;
        form.buildingAddress.state = form.customerAddress.state;
        form.buildingAddress.country = form.customerAddress.country;
        form.buildingAddress.city = form.customerAddress.city;
      }
    },
    searchCustomerByName (searchTerm: string) {
      if (searchTerm.length > 0) {
        this.customerSearchByNameIsSearching = true;
        this.axios.get('/name', {
          'params': {
            'name': searchTerm
          }
        }).then(({ data }) => {
          this.customerSearchByNameIsSearching = false;
          const response = data as SearchCustomerByNameResponse;

          this.customerSearchByNameList = response.result.customers;
        });
      } else {
        this.customerSearchByNameList = [];
      }
    },
    searchCustomerByIc (searchTerm: string) {
      if (searchTerm.length > 0) {
        this.customerSearchByIcIsSearching = true;
        this.axios.get(`/search-ic/${searchTerm}`).then(({ data }) => {
          this.customerSearchByIcIsSearching = false;
          const response = data as SearchCustomerByNameResponse;

          this.customerSearchByIcList = response.result.customers;
        });
      } else {
        this.customerSearchByIcList = [];
      }
    },
    createNewCustomerByName (searchTerm: string) {
      this.allowSearchCustomer = false;
      this.value.name = searchTerm;
    },
    createNewCustomerByIc (searchTerm: string) {
      this.allowSearchCustomer = false;
      this.value.ic = searchTerm;
    },
    selectExistingCustomer (customerId: number, list: CustomerDetailResponse[]) {
      this.allowSearchCustomer = false;

      const form = this.value as RegisterWarrantyWithCustomer;
      form.customerId = customerId;

      const customer = list.find(c => c.id === customerId);

      if (customer) {
        form.name = customer.name;
        form.ic = customer.ic;
        form.phone = customer.phone;
        form.email = customer.email;
        form.customerAddress.address1 = customer.address1;
        form.customerAddress.address2 = customer.address2;
        form.customerAddress.postcode = customer.postcode;
        form.customerAddress.city = customer.city;
        form.customerAddress.state = customer.state;
        form.customerAddress.country = customer.country;
      }
    },
    resetSearchByNameAndSearchByIc () {
      this.allowSearchCustomer = true;
      this.customerSearchByNameList = [];
      this.customerSearchByIcList = [];
      this.isCopyCustomerAddress = false;
    },
    getWarrantyTypeDisplay (type: number) {
      const warranty = this.warrantyTypes.find(w => w.value === type);

      if (warranty) {
        return warranty.text;
      }

      return '';
    }
  }
});
